import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import styles from '@/styles/superstack/base/Button.module.scss';

const Button = ({ mode, size, color = '', ...props }) => {
  const cx = classNames.bind(styles);
  return (
    <button
      type={'button'}
      className={cx([`btn--${mode}`, `btn--${size}`, color ? `btn--${color}` : ''])}
      {...props}
    >
      {props.children}
    </button>
  );
};

const ButtonBlock = ({ mode, size, ...props }) => {
  const cx = classNames.bind(styles);
  return (
    <button
      type={'button'}
      className={cx([`btn--${mode}`, `btn--${size}`])}
      style={{ width: '100%' }}
      {...props}
    >
      {props.children}
    </button>
  );
};

Button.propTypes = {
  /**
   * What mode
   */
  mode: PropTypes.oneOf([
    'default',
    'filled',
    'stroke',
    'text',
    'cancel',
    'm-cancel',
    'borderless',
    'outline',
  ]),
  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xl-landing']),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Button.defaultProps = {
  mode: 'filled',
  size: 'md',
  children: '버튼',
};

export { Button, ButtonBlock };
