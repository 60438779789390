import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from '@/styles/superstack/base/Modal.module.scss';
import IconModalClose from '@/asset/superstack/svgs/icon-modal-close.svg';
import { Button } from '@/src/component/superstack/base/Button';

const SSModal = ({
  isOpen,
  onClose,
  onSubmit,
  onReset,
  btnSubmitName = '적용하기',
  btnCloseName = '취소',
  btnResetName = '초기화',
  hasCloseBtn = true,
  hasResetBtn = false,
  customStyle,
  title,
  ...props
}) => {
  const cx = classNames.bind(styles);
  const defaultStyle = {
    overlay: {},
    content: {
      boxShadow: '0px 10px 37px rgba(0, 0, 0, 0.15)',
      borderRadius: '8px',
      margin: 'auto',
      padding: 0,
      position: 'relative',
      maxHeight: '90%',
    },
  };
  const { overlay = {}, content = {} } = customStyle;

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: { ...defaultStyle.overlay, ...overlay },
        content: { ...defaultStyle.content, ...content },
      }}
      preventScroll={true}
    >
      <div className={cx('ss-modal')}>
        <div className={cx('ss-modal-header')}>{title}</div>
        <div className={cx('ss-modal-body')}>{props.children}</div>
        <div className={cx('ss-modal-footer')}>
          <Button
            size="sm"
            mode="filled"
            onClick={() => {
              const submitted = onSubmit && onSubmit();
              if (submitted !== false) {
                onClose();
              }
            }}
          >
            {btnSubmitName}
          </Button>
          {hasCloseBtn && (
            <Button
              size="sm"
              mode="cancel"
              onClick={() => {
                onClose();
              }}
            >
              {btnCloseName}
            </Button>
          )}
          {hasResetBtn && (
            <Button
              size="sm"
              mode="cancel"
              onClick={() => {
                onReset();
              }}
            >
              {btnResetName}
            </Button>
          )}
        </div>
      </div>
    </ReactModal>
  );
};

const SSInfoModal = ({ isOpen, onClose, customStyle, title, ...props }) => {
  const cx = classNames.bind(styles);
  const defaultStyle = {
    overlay: {
      zIndex: 100,
    },
    content: {
      boxShadow: '0px 10px 37px rgba(0, 0, 0, 0.15)',
      borderRadius: '8px',
      margin: 'auto',
      padding: 0,
      position: 'relative',
      maxHeight: '90%',
    },
  };
  const { overlay = {}, content = {} } = customStyle;

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: { ...defaultStyle.overlay, ...overlay },
        content: { ...defaultStyle.content, ...content },
      }}
      preventScroll={true}
    >
      <div className={cx('ss-modal')}>
        <div className={cx('ss-modal-header')}>{title}</div>
        <div className={cx('ss-modal-body')}>{props.children}</div>
        <div className={cx(['ss-modal-footer', 'center'])}>
          <Button size="sm" mode="filled" onClick={onClose}>
            확인
          </Button>
        </div>
      </div>
    </ReactModal>
  );
};

const SSModal2 = ({
  isOpen,
  hasBottomChild,
  onClose,
  onSubmit,
  onReset,
  btnSubmitName = '닫기',
  btnCloseName = '취소',
  btnResetName = '초기화',
  hasCloseBtn = true,
  hasResetBtn = false,
  customStyle,
  titleL,
  titleR,
  childrenL,
  childrenR,
  childrenB,
  ...props
}) => {
  const cx = classNames.bind(styles);
  const defaultStyle = {
    overlay: {},
    content: {
      boxShadow: '0px 10px 37px rgba(0, 0, 0, 0.15)',
      borderRadius: '8px',
      margin: 'auto',
      padding: 0,
      position: 'relative',
      maxHeight: '90%',
    },
  };
  const { overlay = {}, content = {} } = customStyle;
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: { ...defaultStyle.overlay, ...overlay },
        content: { ...defaultStyle.content, ...content },
      }}
      preventScroll={true}
      {...props}
    >
      <div className={cx('ss-modal-2')}>
        <div className={cx('ss-modal-lr', hasBottomChild ? 'ss-modal-lr-has-bottom-child' : '')}>
          <div className={cx('ss-modal-l')}>
            <div className={cx(['ss-modal-header', 'ss-modal-header-l'])}>{titleL}</div>
            <div className={cx('ss-modal-body-l')}>{childrenL}</div>
          </div>
          <div className={cx('ss-modal-r')}>
            <div className={cx(['ss-modal-header', 'ss-modal-header-r'])}>{titleR}</div>
            <div className={cx('ss-modal-body-r')}>{childrenR}</div>
          </div>
        </div>
        {hasBottomChild && <div className={cx('ss-modal-b')}>{childrenB}</div>}
        <div className={cx('ss-modal-footer')}>
          <Button
            size="sm"
            mode="filled"
            onClick={() => {
              const submitted = onSubmit && onSubmit();
              if (submitted !== false) {
                onClose();
              }
            }}
          >
            {btnSubmitName}
          </Button>
          {hasCloseBtn && (
            <Button size="sm" mode="cancel" onClick={onClose}>
              {btnCloseName}
            </Button>
          )}
          {hasResetBtn && (
            <Button size="sm" mode="cancel" onClick={onReset}>
              {btnResetName}
            </Button>
          )}
        </div>
      </div>
    </ReactModal>
  );
};

const SSConfirmModal = ({
  isOpen,
  onClose,
  onSubmit,
  confirmSubmit,
  customStyle,
  title,
  btnSubmitName = '적용하기',
  btnCloseName = '취소',
  ...props
}) => {
  const cx = classNames.bind(styles);
  const defaultStyle = {
    overlay: {},
    content: {
      boxShadow: '0px 10px 37px rgba(0, 0, 0, 0.15)',
      borderRadius: '8px',
      margin: 'auto',
      padding: 0,
      position: 'relative',
      maxHeight: '90%',
    },
  };
  const { overlay = {}, content = {} } = customStyle;

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: { ...defaultStyle.overlay, ...overlay },
        content: { ...defaultStyle.content, ...content },
      }}
      preventScroll={true}
    >
      <div className={cx('ss-modal')}>
        <div className={cx('ss-modal-header')}>{title}</div>
        <div className={cx('ss-modal-body')}>{props.children}</div>
        <div className={cx('ss-modal-footer')}>
          <Button
            size="sm"
            mode="filled"
            onClick={() => {
              if (confirmSubmit && !confirmSubmit()) {
                return false;
              }
              const submitted = onSubmit && onSubmit();
              if (submitted !== false) {
                onClose();
              }
            }}
          >
            {btnSubmitName}
          </Button>
          <Button size="sm" mode="cancel" onClick={onClose}>
            {btnCloseName}
          </Button>
        </div>
      </div>
    </ReactModal>
  );
};

const SSImageUploadModal = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  hasCloseBtn = true,
  closeIcon = false,
  btnSubmitName = '적용하기',
  btnCloseName = '취소',
  modalType,
  bodyContent,
  ...props
}) => {
  const cx = classNames.bind(styles);
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={cx(['image-upload-modal'])}
      preventScroll={true}
      shouldFocusAfterRender={false}
    >
      <div className={cx(['image-upload-modal-box'])}>
        <SSModalHeader
          title={title}
          headerType={modalType}
          closeIcon={closeIcon}
          onClose={onClose}
        />
        <SSModalBody bodyContent={bodyContent} bodyType={modalType} />
        <SSModalFooter
          footerType={modalType}
          btnSubmitName={btnSubmitName}
          hasCloseBtn={hasCloseBtn}
          btnCloseName={btnCloseName}
          onSubmit={onSubmit}
          onClose={onClose}
          props={props}
        />
      </div>
    </ReactModal>
  );
};

const SSBlankModal = ({ isOpen, onClose, customStyle, ...props }) => {
  const cx = classNames.bind(styles);
  const defaultStyle = {
    overlay: {
      zIndex: 100,
    },
    content: {
      boxShadow: '0px 10px 37px rgba(0, 0, 0, 0.15)',
      borderRadius: '8px',
      margin: 'auto',
      padding: 0,
      position: 'relative',
      maxHeight: '90%',
    },
  };
  const { overlay = {}, content = {} } = customStyle;

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        overlay: { ...defaultStyle.overlay, ...overlay },
        content: { ...defaultStyle.content, ...content },
      }}
      preventScroll={true}
    >
      <div className={cx('ss-modal-blank')}>
        <div className={cx('ss-modal-body')}>{props.children}</div>
      </div>
    </ReactModal>
  );
};

const SSModalHeader = ({ title, headerType, closeIcon, onClose }) => {
  const cx = classNames.bind(styles);
  return (
    <div className={cx([`modal-header--${headerType}`, `modal-header--${headerType}-mobile`])}>
      <div className={cx([`modal-header--${headerType}-title`])}>{title}</div>
      <div style={{ height: '24px', float: 'right', cursor: 'pointer' }}>
        {closeIcon && <IconModalClose onClick={() => onClose()} />}
      </div>
    </div>
  );
};

const SSModalBody = ({ bodyType, bodyContent }) => {
  const cx = classNames.bind(styles);
  return <div className={cx([`modal-body--${bodyType}`])}>{bodyContent}</div>;
};

const SSModalFooter = ({
  footerType,
  btnSubmitName,
  hasCloseBtn,
  btnCloseName,
  onSubmit,
  onClose,
  props,
}) => {
  const cx = classNames.bind(styles);
  return (
    <div className={cx([`modal-footer--${footerType}`])}>
      <button
        onClick={() => {
          const submitted = onSubmit && onSubmit();
          if (submitted !== false) {
            onClose();
          }
        }}
        className={cx([`btn-submit-modal-footer--${footerType}`])}
      >
        {btnSubmitName}
      </button>
      {hasCloseBtn && (
        <button
          className={cx([`btn-close-modal-footer--${footerType}`])}
          onClick={() => {
            onClose();
            props.afterClose && props.afterClose();
          }}
        >
          {btnCloseName}
        </button>
      )}
    </div>
  );
};

SSModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  customStyle: PropTypes.object,
};
SSConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  customStyle: PropTypes.object,
};

SSInfoModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  customStyle: PropTypes.object,
};

SSModal.defaultProps = {
  isOpen: true,
  customStyle: {
    overlay: {},
    content: {
      width: '280px',
    },
  },
};

export { SSModal, SSInfoModal, SSModal2, SSConfirmModal, SSImageUploadModal, SSBlankModal };
